import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-localstorage-backend";
import HttpApi from "i18next-http-backend";

i18n
  .use(Backend) // Enables saving the selected language in local storage
  .use(LanguageDetector) // Enables detecting the user's language
  .use(HttpApi) // Load translations using http
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    fallbackLng: "en", // Use English if the user's language can't be detected
    debug: true,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/translation.json", // load translations from /public/locales/{lng}/translation.json
    },
  });

export default i18n;
