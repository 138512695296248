import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import i18n from "../../i18n";
import { getJWT } from "../customers/mutations/fetch";
import { fetcher } from "../initialize-api";
import { useGetMeetingNotesResponse } from "./types";

export const useGetMeetingNotes = (
  id: string,
): {
  data: useGetMeetingNotesResponse[];
  error: any;
  isLoading: boolean;
  refetch: () => void;
} => {
  const { data, error, isLoading, mutate } = useSWR(
    `/app/crm-logistic/api/meetings/${id}`,
    // `/meetings/${id}`,
    fetcher,
  );

  const refetch = () => {
    mutate();
  };

  return {
    data,
    error,
    isLoading,
    refetch,
  };
};

export async function postMeetingNotes(
  url: string,
  { arg }: { arg: { customerId: string; date: string; comments: string } },
) {
  const token = await getJWT();
  const data = await axios
    .post(
      url,
      arg, // Pass the data directly as the request body
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    )
    .then((response) => {
      toast.success(i18n.t("messages.comments.posted_success"));
      return response;
    })
    .catch((error) => {
      if (error) {
        toast.error("Error posting comment");
      }
    });
  return data?.data;
}

export const usePostMeetingNotes = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    "/app/crm-logistic/api/meetings/",
    // "/meetings/",
    postMeetingNotes,
  );

  return {
    trigger,
    isMutating,
    error,
  };
};

export async function updateMeetingNotes(
  url: string,
  {
    arg,
  }: {
    arg: {
      customerId: string;
      date: string;
      comments: string;
      commentId: string;
    };
  },
) {
  const token = await getJWT();
  const { commentId, ...rest } = arg;
  const data = await axios
    .post(
      `${url}/${commentId}`,
      rest, // Pass the data directly as the request body
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    )
    .then((response) => {
      toast.success("Comment updated successfully");
      return response;
    })
    .catch((error) => {
      if (error) {
        toast.error("Error updating comment");
      }
    });
  return data?.data;
}

export const useUpdateMeetingNotes = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    `/app/crm-logistic/api/meetings`,
    // `/meetings/`,
    updateMeetingNotes,
  );

  return {
    trigger,
    isMutating,
    error,
  };
};
