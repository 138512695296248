import React from "react";
import { DashboardContextProvider } from "./context/dashboard-context";
import { DashboardContent } from "./dashboard-content";

export const DashboardView = () => {
  return (
    <DashboardContextProvider>
      <DashboardContent />
    </DashboardContextProvider>
  );
};
