import { MenuItem, TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { ADD_CUSTOMER_FORM } from "../../AddCustomer/context/Forms/types";

interface Props {
  name: keyof ADD_CUSTOMER_FORM | string;
  label: string;
  control: any; // Update the type of 'control' property
  disabled?: boolean;
  options?: { value: string; label: string }[];
}

function FormSelect({ control, name, label, disabled = true, options }: Props) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <>
          <TextField
            variant="outlined"
            label={label}
            fullWidth
            value={value || ""}
            onChange={onChange}
            onBlur={onBlur}
            inputRef={ref}
            error={!!error}
            helperText={error && error.message}
            disabled={!disabled}
            select
          >
            {options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      )}
    />
  );
}

export default FormSelect;
