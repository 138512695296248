import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import BoxWithShadow from "../../../common/BoxWithShadow";
import Iconify from "../../../common/Iconify";
import { LoadingButton } from "@mui/lab";
import { useCustomerProfileContext } from "../../context/customer-profile-context";
import { FAMILY_MODAL_MODE } from "../../../../api/customers/types";
import { useTranslation } from "react-i18next";

export const NewFamilyMemberCard = () => {
  const {
    family: { handleOpenFamilyModal, setFamilyModalMode },
  } = useCustomerProfileContext();

  const { t } = useTranslation();

  return (
    <BoxWithShadow
      height={320}
      display={"flex"}
      alignItems={"stretch"}
      justifyContent={"center"}
    >
      <Stack
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={3}
      >
        <Iconify icon={"solar:user-plus-rounded-line-duotone"} height={70} />
        <Typography variant="h6" align="center">
          {t("customer_profile.tabs.family.add_family_member")}
        </Typography>
        <LoadingButton
          variant="contained"
          onClick={() => {
            setFamilyModalMode(FAMILY_MODAL_MODE.ADD);
            handleOpenFamilyModal();
          }}
        >
          {t("customer_profile.tabs.family.add")}
        </LoadingButton>
      </Stack>
    </BoxWithShadow>
  );
};
