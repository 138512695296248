export const ROUTES = {
  root: "/",
  dashboard: {
    root: "/dashboard",
    customers: {
      root: "customers",
      add: "add",
      profile: { route: "profile/:id", link: "profile/" },
    },
    users: {
      root: "users",
      add: "add",
    },
    info: "info",
  },
};
