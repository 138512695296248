import { UsersContextProvider } from "../../context/UsersContext";
import UsersTableView from "./view";

function UsersTable() {
  return (
    <UsersContextProvider>
      <UsersTableView />
    </UsersContextProvider>
  );
}

export default UsersTable;
