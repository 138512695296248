import { Box, IconButton, Stack, Typography } from "@mui/material";
import BoxWithShadow from "../../../common/BoxWithShadow";
import { useCustomerProfileContext } from "../../context/customer-profile-context";
import Iconify from "../../../common/Iconify";
import {
  customerAxiosInstance,
  getJWT,
} from "../../../../api/customers/mutations/fetch";
import { fileDownloader } from "../../../../helpers/file-downloader";

interface FileContainerProps {
  url?: any;
}

export const FileContainer = ({ url = null }: FileContainerProps) => {
  const {
    files: { fileInQueue, setFileInQueue },
  } = useCustomerProfileContext();

  const FILE_ICONS: { [key: string]: string } = {
    pdf: "mingcute:pdf-fill",
    png: "mingcute:photo-album-fill",
    jpeg: "mingcute:photo-album-fill",
    jpg: "mingcute:photo-album-fill",
    doc: "mingcute:doc-fill",
    uknown: "mingcute:file-info-fill",
  };

  const handleDownload = async () => {
    const url =
      "http://vmi1569961.contaboserver.net/app/crm-logistic/api/documents/17/layout.png";
    const parts = url.split("/");
    const index = parts.indexOf("documents");
    const nameFile = parts[parts.length - 1];
    const newUrl = parts.slice(index).join("/");
    await fileDownloader(newUrl, nameFile);
  };

  return (
    <BoxWithShadow padding={2}>
      <Stack
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        direction={"row"}
        p={2}
      >
        <Box display={"flex"} gap={3} alignItems={"center"}>
          <Iconify
            icon={FILE_ICONS[fileInQueue?.[0]?.type?.split("/")[1] ?? "uknown"]}
            height={50}
          />
          <Typography variant={"h6"}>{fileInQueue?.[0]?.name}</Typography>
        </Box>

        <Box>
          {url && (
            <IconButton value="download" onClick={handleDownload}>
              <Iconify icon={"solar:download-outline"} height={25} />
            </IconButton>
          )}
          <IconButton aria-label="delete" onClick={() => setFileInQueue(null)}>
            <Iconify icon={"solar:trash-bin-minimalistic-bold"} height={25} />
          </IconButton>
        </Box>
      </Stack>
    </BoxWithShadow>
  );
};
