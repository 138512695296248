import React from "react";
import AddCustomerView from "./view";
import { AddCustomerContextProvider } from "./context";

const AddCustomer = () => {
  return (
    <AddCustomerContextProvider>
      <AddCustomerView />
    </AddCustomerContextProvider>
  );
};

export default AddCustomer;
