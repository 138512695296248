import useSWR from "swr";
import { deleteFile, fetcher } from "../initialize-api";
import { uploadFile } from "../common";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";

export const useExtraDocumentsFiles = (id: string) => {
  const { data, error, isLoading } = useSWR(
    `/app/crm-logistic/api/extra-documents/${id}`,
    // `/extra-documents/${id}`,
    fetcher
  );

  return {
    data,
    error,
    isLoading,
  };
};

export const useUploadExtraDocumentsFile = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    `/app/crm-logistic/api/extra-documents/`,
    // `/extra-documents/`,
    uploadFile
  );

  return {
    trigger,
    isMutating,
    error,
  };
};

export const useDeleteExtraDocumentsFile = () => {
  const { trigger, isMutating } = useSWRMutation(
    "/app/crm-logistic/api/extra-documents/",
    // "/extra-documents/",
    deleteFile // Add 'args' property to the payload object
  );

  return {
    trigger,
    isMutating,
  };
};
