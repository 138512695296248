import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import BoxWithShadow from "../../common/BoxWithShadow";
import FormTextField from "../../common/FormTextfield";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { useAddCustomerContext } from "../context";
import FormPhoneCodeField from "../../common/FormPhoneCodeField";
import { ADD_CUSTOMER_FORM } from "../context/Forms/types";
import { BirthdayDatePicker } from "../../common/BirthdayDatePicker";
import { useTranslation } from "react-i18next";

const AddCustomerView: React.FC = () => {
  const {
    addCustomer: {
      handleAddCustomer,
      handleSubmitAddCustomer,
      addCustomerControl,
      addCustomerLoading,
    },
  } = useAddCustomerContext();

  const { t } = useTranslation();

  const FIELDS_PERSONAL_INFO = [
    {
      name: "firstName",
      label: "add_customer.personal_infos.first_name",
    },
    {
      name: "lastName",
      label: "add_customer.personal_infos.last_name",
    },
    {
      name: "email",
      label: "add_customer.personal_infos.email",
    },
    {
      name: "telephone",
      label: "add_customer.personal_infos.mobile_number",
    },
    {
      name: "mobilePhone",
      label: "add_customer.personal_infos.phone",
    },
    {
      name: "birthday",
      label: "add_customer.personal_infos.birthday",
    },
  ];

  const CONTACT_INFO = [
    {
      name: "address",
      label: "add_customer.address.street",
    },
    {
      name: "zipCode",
      label: "add_customer.address.zip",
    },
    {
      name: "addressNumber",
      label: "add_customer.address.number",
    },
    {
      name: "region",
      label: "add_customer.address.region",
    },
  ];

  const CompanyInfo = [
    {
      name: "companyName",
      label: "Company Name",
    },
  ];
  return (
    <Box>
      <Typography variant={"h4"} fontWeight={700} pb={4}>
        {t("add_customer.add_new_customer")}
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} component={"form"}>
          <BoxWithShadow>
            <Box pb={4}>
              <Typography variant={"h5"} fontWeight={700}>
                {t("add_customer.company_information")}
              </Typography>
            </Box>
            <Box pb={4}>
              <FormTextField
                control={addCustomerControl}
                name={"companyName" as keyof ADD_CUSTOMER_FORM}
                label={t("add_customer.company_name")}
              />
            </Box>
            <Box pb={4}>
              <Typography variant={"h5"} fontWeight={700}>
                {t("add_customer.personal_infos.title")}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {FIELDS_PERSONAL_INFO.map((field) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  gap={4}
                  direction={"column"}
                >
                  {(field.name === "mobilePhone" ||
                    field.name === "telephone") && (
                    <FormPhoneCodeField
                      key={field.name}
                      control={addCustomerControl}
                      name={field.name as keyof ADD_CUSTOMER_FORM}
                      label={t(field.label)}
                    />
                  )}
                  {(field.name === "firstName" ||
                    field.name === "lastName" ||
                    field.name === "email") && (
                    <FormTextField
                      key={field.name}
                      control={addCustomerControl}
                      name={field.name as keyof ADD_CUSTOMER_FORM}
                      label={t(field.label)}
                    />
                  )}

                  {field.name === "birthday" && (
                    <BirthdayDatePicker
                      key={field.name}
                      control={addCustomerControl}
                      name={field.name as keyof ADD_CUSTOMER_FORM}
                      label={t(field.label)}
                      disabled={false}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
          </BoxWithShadow>
          <BoxWithShadow my={4}>
            <Box pb={4}>
              <Typography variant={"h5"} fontWeight={700}>
                {t("add_customer.address.title")}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              {CONTACT_INFO.map((field) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  display={"flex"}
                  gap={4}
                  direction={"column"}
                >
                  <FormTextField
                    key={field.name}
                    control={addCustomerControl}
                    name={field.name as keyof ADD_CUSTOMER_FORM}
                    label={t(field.label)}
                  />
                </Grid>
              ))}
            </Grid>
          </BoxWithShadow>
          <Box display={"flex"} justifyContent={"flex-end"} py={3}>
            <LoadingButton
              variant={"contained"}
              sx={{
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "gray",
                },
              }}
              startIcon={
                <Icon icon={"solar:sd-card-line-duotone"} height={25} />
              }
              loading={addCustomerLoading}
              onClick={handleSubmitAddCustomer((data) =>
                handleAddCustomer(data),
              )}
            >
              {t("add_customer.save")}
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddCustomerView;
