import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Login from "../Login";
import MainLayout from "../layouts/MainLayout";
import { Container } from "@mui/material";
import AddCustomer from "../AddCustomer";
import { ROUTES } from "../../helpers/routes";
import Info from "../Info";
import UsersTable from "../UsersTable";
import Customers from "../Customers";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import * as reactRouterDom from "react-router-dom";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { CustomerProfile } from "../CustomerProfile";
import { DashboardView } from "../Dashboard/dashboard-view";

function AppRouter() {
  return (
    <BrowserRouter>
      <Routes>
        {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
          EmailPasswordPreBuiltUI,
        ])}

        <Route path={ROUTES.root} element={<Login />} />
        <Route path={ROUTES.dashboard.root} element={<MainLayout />}>
          <Route
            index
            element={
              <SessionAuth>
                <DashboardView />
              </SessionAuth>
            }
          />
          <Route
            path={ROUTES.dashboard.customers.root}
            element={
              <SessionAuth>
                <Container>
                  <Outlet />
                </Container>
              </SessionAuth>
            }
          >
            <Route
              index
              element={
                <SessionAuth>
                  <Customers />
                </SessionAuth>
              }
            />
            <Route
              path={ROUTES.dashboard.customers.add}
              element={<AddCustomer />}
            />
            <Route
              path={ROUTES.dashboard.customers.profile.route}
              element={<CustomerProfile />}
            />
          </Route>
          <Route
            path={ROUTES.dashboard.users.root}
            element={
              <Container>
                <Outlet />
              </Container>
            }
          >
            <Route index element={<UsersTable />} />
          </Route>
          <Route path={ROUTES.dashboard.info} element={<Info />} />
        </Route>
        <Route path="/*" element={<>404</>} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
