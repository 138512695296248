import { Icon } from "@iconify/react";
import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../helpers/routes";
import UsersTableList from "../Table";

function UsersTableView() {
  return (
    <Box>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={"bold"} variant="h4">
          Users
        </Typography>
        <Link to={ROUTES.dashboard.users.add}>
          <LoadingButton
            variant="contained"
            sx={{
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            startIcon={<Icon icon={"solar:add-circle-linear"} height={25} />}
          >
            Add user
          </LoadingButton>
        </Link>
      </Stack>
      <Box py={4}>
        <UsersTableList />
      </Box>
    </Box>
  );
}

export default UsersTableView;
