import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import BoxWithShadow from "../../../common/BoxWithShadow";
import Iconify from "../../../common/Iconify";
import { LoadingButton } from "@mui/lab";
import { useCustomerProfileContext } from "../../context/customer-profile-context";
import { FAMILY_MODAL_MODE } from "../../../../api/customers/types";
import { useTranslation } from "react-i18next";

interface Props {
  firstName: string;
  lastName: string;
  phone: string;
  relation: string;
  id: number;
}

export const FamilyMemberCard = ({
  id,
  firstName,
  lastName,
  phone,
  relation,
}: Props) => {
  const {
    family: {
      handleOpenFamilyModal,
      setFamilyModalMode,
      setFamilyMemberId,
      familyReset,
      handleDeleteFamilyMember,
    },
  } = useCustomerProfileContext();

  const { t } = useTranslation();

  return (
    <BoxWithShadow
      minHeight={320}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Stack
        display={"flex"}
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={3}
      >
        <Iconify icon={"solar:user-circle-outline"} height={70} />
        <Box>
          <Typography fontSize={14} color={"gray"} align="center">
            {t(`customer_profile.tabs.family.relationship.${relation}`)}
          </Typography>
          <Typography variant="h6" align="center">
            {firstName} {lastName}
          </Typography>
        </Box>
        <Typography fontSize={14} color={"gray"} align="center">
          {phone}
        </Typography>
        <Stack direction={"row"} gap={2}>
          <LoadingButton
            variant="contained"
            color="error"
            onClick={() => handleDeleteFamilyMember(id)}
          >
            {t("generic.delete")}
          </LoadingButton>

          <LoadingButton
            variant="contained"
            onClick={() => {
              setFamilyModalMode(FAMILY_MODAL_MODE.EDIT);
              familyReset({
                firstName,
                lastName,
                mobilePhone: phone,
                relationship: relation,
              });
              setFamilyMemberId(id);
              handleOpenFamilyModal();
            }}
          >
            {t("generic.edit")}
          </LoadingButton>
        </Stack>
      </Stack>
    </BoxWithShadow>
  );
};
