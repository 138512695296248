import { LoadingButton, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { handleDownload } from "../../../../../helpers/file-downloader";
import { FilesTableContainer } from "../../../../common/FilesTable/files-table-container";
import Iconify from "../../../../common/Iconify";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";
import { FileContainer } from "../../files/file-container";
import { useInsuranceFiles } from "../../../../../api/insurance/insurance";
import { useTranslation } from "react-i18next";

export const InsurancePanel = () => {
  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const [endDate, setEndDate] = React.useState<DateTime | null>(null);

  const {
    customerId,
    files: { setFileInQueue, fileInQueue },
    insuranceFile: {
      uploadInsuranceFile,
      insuranceFileIsUploading,
      deleteInsuranceFile,
    },
  } = useCustomerProfileContext();

  const [insuranceType, setInsuranceType] = React.useState("HOUSE");

  const {
    data: insuranceFilesDataRaw,
    isLoading: insuranceFilesLoading,
    mutate: mutateFiles,
  } = useInsuranceFiles(customerId ?? "");

  const insuranceFilesData = useMemo(() => {
    return insuranceFilesDataRaw?.map((file: any) => file) ?? [];
  }, [insuranceFilesDataRaw]);

  const handleFileDrop = useCallback((acceptedFiles: File[]) => {
    setFileInQueue(acceptedFiles);
  }, []);

  const handleUpload = () => {
    const formData = new FormData();
    if (!fileInQueue || !customerId) return;
    formData.append("customerId", customerId);
    formData.append("insuranceType", insuranceType);
    formData.append("document", fileInQueue?.[0]);
    formData.append("fileName", fileInQueue?.[0]?.name);
    formData.append(
      "startDate",
      startDate ? startDate.toString() : (new Date().toISOString() as string),
    );
    formData.append(
      "endDate",
      endDate ? endDate.toString() : (new Date().toISOString() as string),
    );

    uploadInsuranceFile(formData, {
      onSuccess: () => {
        setFileInQueue(null);
        mutateFiles();
      },
    }); // Pass formData directly instead of wrapping it in an object
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
    },
    multiple: false,
  });
  const handleInsuranceTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setInsuranceType(event.target.value);
  };

  const { t } = useTranslation();

  const COLUMNS: { name: string; align: "left" | "right" | "center" }[] = [
    {
      name: t("customer_profile.tabs.insurance_tab.files_table.name"),
      align: "left",
    },
    {
      name: t("customer_profile.tabs.insurance_tab.files_table.type"),
      align: "center",
    },
    {
      name: t("customer_profile.tabs.insurance_tab.files_table.start_date"),
      align: "left",
    },
    {
      name: t("customer_profile.tabs.insurance_tab.files_table.end_date"),
      align: "left",
    },
    {
      name: t("customer_profile.tabs.insurance_tab.files_table.actions"),
      align: "right",
    },
  ];

  return (
    <TabPanel value="insurance">
      <Grid container spacing={2} component={"form"}>
        <Grid item xs={12}>
          <Stack direction={"row"} gap={2}>
            <TextField
              select
              fullWidth
              variant="outlined"
              label={t("customer_profile.tabs.insurance_tab.insurance_type")}
              value={insuranceType}
              onChange={handleInsuranceTypeChange}
            >
              <MenuItem value="HOUSE">
                {t("customer_profile.tabs.insurance_tab.types.HOME")}
              </MenuItem>
              <MenuItem value="LEGAL">
                {t("customer_profile.tabs.insurance_tab.types.LEGAL")}
              </MenuItem>
              <MenuItem value="HEALTH">
                {t("customer_profile.tabs.insurance_tab.types.HEALTH")}
              </MenuItem>
              <MenuItem value="VEHICLE">
                {t("customer_profile.tabs.insurance_tab.types.VEHICLE")}
              </MenuItem>
              <MenuItem value="THIRD_PARTY">
                {t("customer_profile.tabs.insurance_tab.types.THIRD_PARTY")}
              </MenuItem>
            </TextField>

            <DatePicker
              label={t(
                "customer_profile.tabs.insurance_tab.insurance_start_date",
              )}
              sx={{ width: 1 }}
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
            <DatePicker
              label={t(
                "customer_profile.tabs.insurance_tab.insurance_end_date",
              )}
              sx={{ width: 1 }}
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {fileInQueue ? (
            <Stack direction={"column"} gap={3}>
              <FileContainer />
              <LoadingButton
                variant={"contained"}
                sx={{
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                }}
                startIcon={
                  <Iconify
                    icon={"solar:upload-minimalistic-line-duotone"}
                    height={25}
                  />
                }
                onClick={handleUpload}
                loading={insuranceFileIsUploading}
                disabled={startDate === null || endDate === null}
              >
                {t("generic.upload")}
              </LoadingButton>
            </Stack>
          ) : (
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed",
                borderColor: "grey.300",
                padding: "20px",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>Drop the files here ...</Typography>
              ) : (
                <Typography>{t("generic.drag_and_drop")}</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider>
          <Typography>
            {t("customer_profile.tabs.insurance_tab.files")}
          </Typography>
        </Divider>

        <FilesTableContainer columns={COLUMNS} rows={insuranceFilesData}>
          {insuranceFilesData?.map((row: any) => (
            <TableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.fileName}
              </TableCell>
              <TableCell align="center">
                {t(
                  `customer_profile.tabs.insurance_tab.types.${row.insuranceType}`,
                )}
              </TableCell>
              <TableCell align="left">
                {DateTime.fromISO(row.startDate).toFormat("dd/MM/yyyy")}
              </TableCell>
              <TableCell align="left">
                {" "}
                {DateTime.fromISO(row.endDate).toFormat("dd/MM/yyyy")}
              </TableCell>
              <TableCell>
                <Stack
                  display={"flex"}
                  alignItems={"right"}
                  justifyContent={"right"}
                  direction={"row"}
                  p={2}
                  width={1}
                >
                  <IconButton
                    value="download"
                    onClick={() => handleDownload(row?.document ?? "")}
                  >
                    <Iconify icon={"solar:download-outline"} height={25} />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={() =>
                      deleteInsuranceFile(
                        {
                          id: row.id,
                          type: row?.insuranceType,
                        },
                        { onSuccess: () => mutateFiles() },
                      )
                    }
                  >
                    <Iconify
                      icon={"solar:trash-bin-minimalistic-bold"}
                      height={25}
                    />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </FilesTableContainer>
      </Box>
    </TabPanel>
  );
};
