import { LoadingButton } from "@mui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import Iconify from "../../../../common/Iconify";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";

const UpdateMember = () => {
  const {
    customerId,
    family: {
      newFamilyMemberLoading,
      handleFamilySubmit,
      handleUpdateFamilyMember,
      familyMemberId,
    },
  } = useCustomerProfileContext();

  const { t } = useTranslation();

  return (
    <LoadingButton
      variant={"contained"}
      sx={{
        backgroundColor: "black",
        "&:hover": {
          backgroundColor: "gray",
        },
      }}
      startIcon={<Iconify icon={"solar:sd-card-line-duotone"} height={25} />}
      loading={newFamilyMemberLoading}
      onClick={handleFamilySubmit((data) =>
        handleUpdateFamilyMember({
          ...data,
          customerId,
          id: familyMemberId ?? 0,
        }),
      )}
    >
      {t("generic.update")}
    </LoadingButton>
  );
};

export default UpdateMember;
