import { zodResolver } from "@hookform/resolvers/zod";
import { FC, PropsWithChildren, createContext, useContext } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { addNewCustomer } from "../../../api/customers/mutations/query";
import { Customer } from "../../../types/customer";
import { ADD_CUSTOMER_INITIAL_VALUES } from "./Forms/customerInitialValues";
import { ADD_CUSTOMER_VALIDTION } from "./Forms/validationSchemas";

type AddCustomerContextType = ReturnType<typeof useAddCustomerContextProvider>;

const AddCustomerContext = createContext({} as AddCustomerContextType);

interface AddCustomerContextProviderProps {}

export const useAddCustomerContextProvider = (
  props: AddCustomerContextProviderProps
) => {
  const { handleSubmit: handleSubmitAddCustomer, control: addCustomerControl } =
    useForm<Customer>({
      defaultValues: ADD_CUSTOMER_INITIAL_VALUES,
      resolver: zodResolver(ADD_CUSTOMER_VALIDTION),
    });

  const {
    data: addCustomerData,
    isLoading: addCustomerLoading,
    mutate,
  } = useMutation(addNewCustomer);

  const handleAddCustomer = (data: Customer) => {
    mutate(data, {
      onSuccess: (data) => {
        toast.success("Customer added successfully");
      },
      onError: (error) => {
        toast.error("Failed to add customer");
      },
    });
  };
  return {
    addCustomer: {
      handleAddCustomer,
      handleSubmitAddCustomer,
      addCustomerControl,
      addCustomerLoading,
    },
  };
};

export const AddCustomerContextProvider: FC<PropsWithChildren> = (props) => {
  const { children, ...options } = props;
  const context = useAddCustomerContextProvider(options);
  return (
    <AddCustomerContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </AddCustomerContext.Provider>
  );
};

export const useAddCustomerContext = () => useContext(AddCustomerContext);
