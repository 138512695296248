import axios, { AxiosResponse } from "axios";
import Session from "supertokens-auth-react/recipe/session";
import { Customer, FamilyMember, PaginatedCustomers } from "../types";

async function getJWT() {
  if (await Session.doesSessionExist()) {
    let jwt = await Session.getAccessToken();
    return jwt;
  }
}

const customerAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_JAVA,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    Authorization: "Bearer ",
  },
});

export const fetchCustomers = async (
  page: number,
  pageSize: number,
  name: string
): Promise<AxiosResponse<PaginatedCustomers>> => {
  const token = await getJWT();
  return customerAxiosInstance.get(
    `/customers?page=${page}&size=${pageSize}&name=${name}`,
    {
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const fetchCustomersById = async (
  id: string
): Promise<AxiosResponse<Customer>> => {
  const token = await getJWT();
  return customerAxiosInstance.get(`/customers/${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const fetchCustomersFamilyById = async (
  id: string
): Promise<AxiosResponse<FamilyMember[]>> => {
  const token = await getJWT();
  return customerAxiosInstance.get(`/family/?customerId=${id}`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};
