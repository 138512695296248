import { Icon } from "@iconify/react";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const Info = () => {
  const info = [
    {
      icon: "solar:phone-calling-bold",
      text: "XXXXXXXXXX",
    },
    {
      icon: "solar:letter-bold",
      text: "test@example.com",
    },
  ];
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Typography variant="h2" fontWeight={"bolder"} pb={4}>
            Link Enterprise
          </Typography>
          <Typography variant="h6" maxWidth={"sm"}>
            Big thanks for choosing Link Enterprise 🎉 We're genuinely thrilled
            to be on this journey with you. Your trust in us is what keeps the
            innovation train rolling. While you're cruising through all the cool
            features of Link Enterprise, remember, your satisfaction is our main
            jam. Cheers to you, and here's to delivering nothing but excellence
            for all your Link Enterprise needs.
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="body1" maxWidth={"sm"}>
            If you have any questions, comments, or concerns, please don't
            hesitate to reach out to us at:
          </Typography>
          <Stack display={"flex"} direction={"column"} gap={2} pt={2}>
            {info.map((item) => (
              <Box
                sx={{ boxShadow: 1 }}
                p={2}
                display={"flex"}
                gap={2}
                alignItems={"center"}
              >
                <Avatar sx={{ bgcolor: "primary" }}>
                  <Icon icon={item.icon} />
                </Avatar>
                <Typography variant="body1" maxWidth={"sm"}>
                  {item?.text}
                </Typography>
              </Box>
            ))}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Info;
