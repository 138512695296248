import { CommentsContextProvider } from "./context/comments-context";
import { CustomerProfileContextProvider } from "./context/customer-profile-context";
import { CustomerProfileContent } from "./view/customer-profile-content";

export const CustomerProfile = () => {
  return (
    <CustomerProfileContextProvider>
      <CommentsContextProvider>
        <CustomerProfileContent />
      </CommentsContextProvider>
    </CustomerProfileContextProvider>
  );
};
