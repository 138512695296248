export type PaginatedCustomers = {
  totalPages: number;
  totalElements: number;
  pageable: {
    unpaged: boolean;
    paged: boolean;
    pageNumber: number;
    pageSize: number;
    offset: number;
    sort: {
      sorted: boolean;
      unsorted: boolean;
      empty: boolean;
    };
  };
  numberOfElements: number;
  size: number;
  content: Customer[];
  number: number;
  sort: {
    sorted: boolean;
    unsorted: boolean;
    empty: boolean;
  };
  first: boolean;
  last: boolean;
  empty: boolean;
};

export type Customer = {
  id?: number;
  firstName: string;
  lastName: string;
  address: string;
  addressNumber: number;
  region: string;
  zipCode: string;
  birthday: string;
  telephone: string;
  mobilePhone: string;
  countryCode: string;
  email: string;
  pet: boolean;
  companyName: string;
};

export type FamilyMember = {
  id?: number;
  firstName: string;
  lastName: string;
  mobilePhone: string;
  relationship: string;
  customerId?: string;
  customer?: Customer;
};

export enum FamilyRelationship {
  CHILD = "Child",
  WIFE = "Wife",
  HUSBAND = "Husband",
}

export enum FAMILY_MODAL_MODE {
  ADD = "add",
  EDIT = "edit",
}
