import LanguageIcon from "@mui/icons-material/Language";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import path from "path";
import React from "react";
import { Outlet } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/emailpassword";
import { ROUTES } from "../../../../helpers/routes";
import { on } from "events";
import { useTranslation } from "react-i18next";
import i18n from "../../../../i18n";

function MainLayoutView() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const { t } = useTranslation();

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  const pages = [
    {
      title: t("menu.dashboard"),
      path: ROUTES?.dashboard?.root,
      onClick: () => (window.location.href = ROUTES?.dashboard?.root),
    },
    { title: t("menu.docs"), path: "/", onClick: () => alert("Docs") },
    { title: t("menu.logout"), path: "/signout", onClick: () => onLogout() },
  ];
  const languages = [
    { label: "Greek", tag: "gr" },
    { label: "English", tag: "en" },
    { label: "German", tag: "de" },
  ];

  async function onLogout() {
    await signOut();
    window.location.href = "/"; // or to wherever your logic page is
  }

  return (
    <Box m={0}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "#fcfcfd", color: "black" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              width={150}
              component={"img"}
              src="/assets/branding/logo.png"
              alt="logo"
              sx={{ display: { xs: "none", md: "flex" } }}
            />

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page?.title} onClick={page?.onClick}>
                    <Typography textAlign="center">{page?.title}</Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              width={150}
              component={"img"}
              src="/assets/branding/logo.png"
              alt="logo"
              sx={{ display: { xs: "flex", md: "none" } }}
            />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "right",
              }}
            >
              {pages.map((page) => (
                <Button
                  key={page?.title}
                  onClick={page?.onClick}
                  sx={{ my: 2, color: "black", display: "block" }}
                >
                  {page?.title}
                </Button>
              ))}
            </Box>

            <Divider
              orientation="vertical"
              variant="middle"
              flexItem
              sx={{ mx: 2 }}
            />

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <LanguageIcon />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {languages.map((language) => (
                  <MenuItem
                    key={language?.tag}
                    onClick={() => changeLanguage(language?.tag)}
                  >
                    <Typography textAlign="center">
                      {language?.label}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth={"xl"} sx={{ py: 10 }}>
        <Outlet />
      </Container>
    </Box>
  );
}

export default MainLayoutView;
