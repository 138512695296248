import React, { FC, PropsWithChildren, createContext, useContext } from "react";

type MainLayoutContextType = ReturnType<typeof useMainLayoutContextProvider>;

const MainLayoutContext = createContext({} as MainLayoutContextType);

interface MainLayoutContextProviderProps {}

export const useMainLayoutContextProvider = (
  props: MainLayoutContextProviderProps
) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [openPopper, setOpenPopper] = React.useState(false);
  const [anchorElPopper, setAnchorElPopper] =
    React.useState<null | HTMLElement>(null);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickPopper = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPopper(event.currentTarget);
    setOpenPopper((previousOpen) => !previousOpen);
  };

  const canBeOpenPopper = openPopper && Boolean(anchorElPopper);
  const id = canBeOpenPopper ? "avatar-popper-menu" : undefined;
  return {
    drawer: {
      openDrawer,
      handleDrawerOpen,
      handleDrawerClose,
    },
    popper: {
      openPopper,
      anchorElPopper,
      handleClickPopper,
      canBeOpenPopper,
      id,
    },
  };
};

export const MainLayoutContextProvider: FC<PropsWithChildren> = (props) => {
  const { children, ...options } = props;
  const context = useMainLayoutContextProvider(options);
  return (
    <MainLayoutContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </MainLayoutContext.Provider>
  );
};

export const useMainLayoutContext = () => useContext(MainLayoutContext);
