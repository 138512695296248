import axios from "axios";
import { toast } from "react-toastify";
import { getJWT } from "./customers/mutations/fetch";

export async function uploadFile(url: string, { arg }: { arg: FormData }) {
  const token = await getJWT();
  const data = await axios
    .post(
      url,
      arg, // Pass the data directly as the request body
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + token,
          type: "formData",
        },
      }
    )
    .then((response) => {
      toast.success("File uploaded successfully");
      return response;
    })
    .catch((error) => {
      if (error) {
        toast.error("Error uploading file");
      }
    });
  return data?.data;
}
