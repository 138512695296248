import { Customer } from "../../../../api/customers/types";

export const ADD_CUSTOMER_INITIAL_VALUES: Customer = {
  firstName: "",
  lastName: "",
  address: "",
  addressNumber: 0,
  region: "",
  zipCode: "",
  birthday: "",
  telephone: "",
  mobilePhone: "",
  countryCode: "",
  email: "",
  pet: false,
  companyName: "",
};
