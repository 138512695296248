import { LoadingButton } from "@mui/lab";
import React from "react";
import Iconify from "../../../../common/Iconify";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";

interface AddNewMemberProps {
  // Add any props you need for the component here
}

const AddNewMember: React.FC<AddNewMemberProps> = () => {
  const {
    family: {
      newFamilyMemberLoading,
      handleFamilySubmit,
      handleNewFamilyMember,
    },
  } = useCustomerProfileContext();

  return (
    <LoadingButton
      variant={"contained"}
      sx={{
        backgroundColor: "black",
        "&:hover": {
          backgroundColor: "gray",
        },
      }}
      startIcon={<Iconify icon={"solar:sd-card-line-duotone"} height={25} />}
      loading={newFamilyMemberLoading}
      onClick={handleFamilySubmit((data) => handleNewFamilyMember(data))}
    >
      Save
    </LoadingButton>
  );
};

export default AddNewMember;
