import { zodResolver } from "@hookform/resolvers/zod";
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  addNewCustomer,
  deleteCustomerById,
} from "../../../api/customers/mutations/query";
import { getCustomers } from "../../../api/customers/queries/query";
import { Customer } from "../../../types/customer";
import { ADD_CUSTOMER_INITIAL_VALUES } from "../../AddCustomer/context/Forms/customerInitialValues";
import { ADD_CUSTOMER_VALIDTION } from "./Forms/validationSchemas";
// import { useDebounce } from "use-debounce";

type CustomersContextType = ReturnType<typeof useCustomersContextProvider>;

const CustomersContext = createContext({} as CustomersContextType);

interface CustomersContextProviderProps {}

export const useCustomersContextProvider = (
  props: CustomersContextProviderProps
) => {
  const [searchValue, setSearchValue] = useState<string>("");
  // const [searchTerm] = useDebounce(searchValue, 500);
  const [paginationTable, setPaginationTable] = useState({
    pageSize: 10,
    page: 0,
  });

  const handleSearchField = (value: string) => {
    setSearchValue(value);
  };

  const {
    data: customersList,
    isLoading: customersListLoading,
    error: customersListError,
    refetch: refetchCustomersList,
  } = useQuery(
    ["customers", paginationTable.page, paginationTable.pageSize],
    () =>
      getCustomers(paginationTable.page, paginationTable.pageSize, searchValue)
  );

  const customersListData = useMemo(() => {
    return (
      customersList?.content?.map((customer, index) => {
        return {
          id: index,
          ...customer,
        };
      }) ?? []
    );
  }, [customersList]);

  const { handleSubmit: handleSubmitAddUser, control: addUserControl } =
    useForm<Customer>({
      defaultValues: ADD_CUSTOMER_INITIAL_VALUES,
      resolver: zodResolver(ADD_CUSTOMER_VALIDTION),
    });

  const handleAddUserSubmit = (data: Customer) => {
    addNewCustomer(data);
  };

  const { data, mutate: deleteCustomerMutate } =
    useMutation(deleteCustomerById);

  const handleDeleteCustomerById = (id: number) => {
    deleteCustomerMutate(id);
    refetchCustomersList();
  };

  const totalPages = useMemo(() => {
    return customersList?.totalElements;
  }, [customersList]);
  return {
    addCustomer: {
      addUserControl,
      handleSubmitAddUser,
      handleAddUserSubmit,
    },
    customersList: {
      customersListData,
      customersListLoading,
      customersListError,
      totalPages,
    },
    deleteCustomer: {
      handleDeleteCustomerById,
    },
    search: {
      searchValue,
      handleSearchField,
    },
    pagination: {
      paginationTable,
      setPaginationTable,
    },
  };
};

export const CustomersContextProvider: FC<PropsWithChildren> = (props) => {
  const { children, ...options } = props;
  const context = useCustomersContextProvider(options);
  return (
    <CustomersContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

export const useCustomersContext = () => useContext(CustomersContext);
