import { PaginatedCustomers, Customer } from "../../../types/customer";
import { FamilyMember } from "../types";
import {
  fetchCustomers,
  fetchCustomersById,
  fetchCustomersFamilyById,
} from "./fetch";

export const getCustomers = async (
  page: number,
  pageSize: number,
  name: string
): Promise<PaginatedCustomers> => {
  const response = await fetchCustomers(page, pageSize, name);
  return response.data;
};

export const getCustomerById = async (id: string): Promise<Customer> => {
  const response = await fetchCustomersById(id);
  return response.data;
};

export const getCustomerFamilysById = async (
  id: string
): Promise<FamilyMember[]> => {
  const response = await fetchCustomersFamilyById(id);
  return response.data;
};
