import { LoadingButton, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { DateTime } from "luxon";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { getJWT } from "../../../../../api/customers/mutations/fetch";
import { FilesTableContainer } from "../../../../common/FilesTable/files-table-container";
import Iconify from "../../../../common/Iconify";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";
import { FileContainer } from "../../files/file-container";
import { handleDownload } from "../../../../../helpers/file-downloader";
import { useTranslation } from "react-i18next";

async function getFiles(url: string) {
  const token = await getJWT();
  const data = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return data?.data;
}

export const TaxesPanel = () => {
  const [startDate, setStartDate] = React.useState<DateTime | null>(null);
  const { t } = useTranslation();
  const {
    taxes: { uploadTaxesFile, taxesFileIsUploading, deleteTaxesFile },
    files: { setFileInQueue, fileInQueue },
  } = useCustomerProfileContext();

  const { id: customerId } = useParams();

  const { data: taxFiles, mutate: refetchTaxFiles } = useSWR(
    // `/taxes/${customerId}`,
    `/app/crm-logistic/api/taxes/${customerId}`,
    getFiles,
  );

  const handleFileDrop = useCallback((acceptedFiles: File[]) => {
    setFileInQueue(acceptedFiles);
  }, []);

  const handleUpload = async () => {
    const formData = new FormData();
    if (!fileInQueue || !customerId) return;
    formData.append("customerId", customerId);
    formData.append("document", fileInQueue?.[0]);
    formData.append("fileName", fileInQueue?.[0]?.name);
    formData.append(
      "date",
      startDate ? startDate.toString() : (new Date().toISOString() as string),
    );

    uploadTaxesFile(formData, { onSuccess: () => refetchTaxFiles() });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
    },
    multiple: false,
  });

  const COLUMNS: { name: string; align: "left" | "right" | "center" }[] = [
    {
      name: t("table.name"),
      align: "left",
    },
    {
      name: t("generic.start_date"),
      align: "center",
    },

    {
      name: t("generic.actions"),
      align: "right",
    },
  ];

  return (
    <TabPanel value="taxes">
      <Grid container spacing={2} component={"form"}>
        <Grid item xs={12}>
          <Stack direction={"row"} gap={2}>
            <DatePicker
              label={t("generic.start_date")}
              sx={{ width: 1 }}
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              slotProps={{
                textField: {
                  required: true,
                },
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          {fileInQueue ? (
            <Stack direction={"column"} gap={3}>
              <FileContainer />
              <LoadingButton
                variant={"contained"}
                sx={{
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                }}
                startIcon={
                  <Iconify
                    icon={"solar:upload-minimalistic-line-duotone"}
                    height={25}
                  />
                }
                onClick={handleUpload}
                loading={taxesFileIsUploading}
                disabled={!startDate}
              >
                {t("generic.upload")}
              </LoadingButton>
            </Stack>
          ) : (
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed",
                borderColor: "grey.300",
                padding: "20px",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>Drop the files here ...</Typography>
              ) : (
                <Typography>{t("generic.drag_and_drop")}</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider>
          <Typography>{t("generic.files")}</Typography>
        </Divider>
        <FilesTableContainer columns={COLUMNS} rows={taxFiles ?? []}>
          {Array.isArray(taxFiles) &&
            taxFiles.map((row: any) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.fileName}
                </TableCell>
                <TableCell align="center">
                  {DateTime.fromISO(row.date).toFormat("dd/MM/yyyy")}
                </TableCell>

                <TableCell align="right">
                  <Stack
                    display={"flex"}
                    alignItems={"right"}
                    justifyContent={"right"}
                    direction={"row"}
                    p={2}
                    width={1}
                  >
                    <IconButton
                      value="download"
                      onClick={() => handleDownload(row?.document ?? "")}
                    >
                      <Iconify icon={"solar:download-outline"} height={25} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteTaxesFile(
                          { id: row?.id },
                          { onSuccess: () => refetchTaxFiles() },
                        )
                      }
                    >
                      <Iconify
                        icon={"solar:trash-bin-minimalistic-bold"}
                        height={25}
                      />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </FilesTableContainer>
      </Box>
    </TabPanel>
  );
};
