import { FC, PropsWithChildren, createContext, useContext } from "react";

type CommentsContextType = ReturnType<typeof useCommentsContextProvider>;

const CommentsContext = createContext({} as CommentsContextType);

interface CommentsContextProviderProps {}

export const useCommentsContextProvider = (
  props: CommentsContextProviderProps
) => {
  return {};
};

export const CommentsContextProvider: FC<PropsWithChildren> = (props) => {
  const { children, ...options } = props;
  const context = useCommentsContextProvider(options);
  return (
    <CommentsContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </CommentsContext.Provider>
  );
};

export const useCommentsContext = () => useContext(CommentsContext);
