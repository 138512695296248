import { Box, Tab, Tabs } from "@mui/material";
import React, { useMemo } from "react";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";
import { TabList } from "@mui/lab";
import { useTranslation } from "react-i18next";

export const TabsHead = () => {
  // Add your component logic here
  const {
    tabs: { tabsValue, handleTabsValue },
  } = useCustomerProfileContext();

  const indiactorColor = useMemo(() => {
    switch (tabsValue) {
      case "family":
        return "#64b0c4";
      case "insurance":
        return "#fec33e";
      case "taxes":
        return "#65c5b3";
      case "threePylons":
        return "#f5896b";
      case "extraDocuments":
        return "#9295c7";
      case "comments":
        return "#ff9900";
      default:
        return "black";
    }
  }, [tabsValue]);

  const { t } = useTranslation();

  return (
    <Box>
      <TabList
        onChange={handleTabsValue}
        TabIndicatorProps={{ sx: { backgroundColor: indiactorColor } }}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          label={t("customer_profile.tabs.family_members")}
          value="family"
          sx={{ "&.Mui-selected": { color: "#64b0c4" }, color: "#64b0c4" }}
        />
        <Tab
          label={t("customer_profile.tabs.insurance")}
          value="insurance"
          sx={{ "&.Mui-selected": { color: "#fec33e" }, color: "#fec33e" }}
        />
        <Tab
          label={t("customer_profile.tabs.taxes")}
          value="taxes"
          sx={{ "&.Mui-selected": { color: "#65c5b3" }, color: "#65c5b3" }}
        />
        <Tab
          label={t("customer_profile.tabs.three_pylons")}
          value="threePylons"
          sx={{ "&.Mui-selected": { color: "#f5896b" }, color: "#f5896b" }}
        />
        <Tab
          label={t("customer_profile.tabs.extra_documents")}
          value="extraDocuments"
          sx={{ "&.Mui-selected": { color: "#9295c7" }, color: "#9295c7" }}
        />
        <Tab
          label={t("customer_profile.tabs.notes")}
          value="comments"
          sx={{ "&.Mui-selected": { color: "#ff9900" }, color: "#ff9900" }}
        />
      </TabList>
    </Box>
  );
};
