import { IconButton, Stack, Typography } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import TableWithSearch from "../../common/TableWithSearch";
import { Icon } from "@iconify/react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../helpers/routes";
import { useCustomersContext } from "../context";

const CustomersTableList = () => {
  const {
    customersList: { customersListData, customersListLoading },
    deleteCustomer: { handleDeleteCustomerById },
  } = useCustomersContext();

  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Link
            to={ROUTES.dashboard.customers.profile.link + params.row.id}
            style={{ textDecoration: "none", color: "black" }}
          >
            <Typography sx={{ textDecoration: "none" }}>
              {params.row.firstName || ""} {params.row.lastName || ""}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "mobilePhone",
      headerName: "Phone",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.address || ""} ${params.row.addressNumber || ""}, ${params.row.zipCode || ""}, ${params.row.region || ""}`,
    },
    {
      field: "actions",
      headerName: "",
      align: "right",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Stack direction={"row"} gap={2}>
          <IconButton
            aria-label="delete"
            onClick={() =>
              navigate(ROUTES.dashboard.customers.profile.link + params.row.id)
            }
          >
            <Icon icon={"solar:info-circle-broken"} height={25} />
          </IconButton>
          <IconButton aria-label="edit">
            <Icon icon={"solar:pen-broken"} height={25} />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteCustomerById(params.row.id)}
          >
            <Icon icon={"solar:trash-bin-minimalistic-bold"} height={25} />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <TableWithSearch
      loading={customersListLoading}
      rows={customersListData}
      columns={columns}
    />
  );
};

export default CustomersTableList;
