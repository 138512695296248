import { LoadingButton, TabContext } from "@mui/lab";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import BoxWithShadow from "../../common/BoxWithShadow";
import Iconify from "../../common/Iconify";
import { useCustomerProfileContext } from "../context/customer-profile-context";
import FormTextField from "../../common/FormTextfield";
import { BirthdayDatePicker } from "../../common/BirthdayDatePicker";
import FormPhoneCodeField from "../../common/FormPhoneCodeField";
import { TabsHead } from "../components/Tabs/head/tabs-head";
import { FamilyPanel } from "../components/Tabs/panel/family-panel";
import { FamilyMemberDialog } from "../components/family/family-member-dialog";
import { InsurancePanel } from "../components/Tabs/panel/insurance-panel";
import { Customer } from "../../../types/customer";
import { ExtraDocumentsPanel } from "../components/Tabs/panel/extra-documents-panel";
import { TaxesPanel } from "../components/Tabs/panel/taxes-panel";
import { ThreePylonsPanel } from "../components/Tabs/panel/three-pylons-panel";
import { MeetingNotesPanel } from "../components/Tabs/panel/meeting-note-panel";
import { useTranslation } from "react-i18next";
import { DeleteCustomerDialog } from "../../Dialogs/DeleteCustomerDialog";

export const CustomerProfileContent = () => {
  const {
    customerId,
    customersDetails: {
      customerDetails,
      customerDetailsLoading,
      handleUpdateCustomerSubmit,
      updateCustomerMutate,
    },
    form: { control },
    edit: { editMode, setEditMode },
    tabs: { tabsValue },
    deleteCustomerDialog: { handleDeleteCustomerDialogOpen },
  } = useCustomerProfileContext();

  const { t } = useTranslation();
  return (
    <Box>
      <BoxWithShadow>
        <Stack
          display={"flex"}
          direction={{ xs: "column", md: "row" }}
          gap={10}
        >
          <Stack
            display={"flex"}
            direction={"column"}
            gap={3}
            justifyContent={"center"}
            alignItems={"center"}
            minWidth={300}
          >
            <Iconify icon={"solar:user-bold-duotone"} height={150} />
            {!customerDetailsLoading ? (
              <Box textAlign={"center"}>
                <Typography variant="h6">
                  {customerDetails?.firstName}
                </Typography>
                <Typography variant="h6">
                  {customerDetails?.lastName}
                </Typography>
              </Box>
            ) : (
              <Box>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "2rem" }}
                  width={100}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "2rem" }}
                  width={100}
                />
              </Box>
            )}
            <Stack display={"flex"} direction={"row"} gap={3}>
              {!editMode ? (
                <>
                  <LoadingButton
                    variant="contained"
                    color="error"
                    // onClick={handleDeleteCustomerById}
                    onClick={() => handleDeleteCustomerDialogOpen()}
                  >
                    {t("customer_profile.delete")}
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => setEditMode(true)}
                  >
                    {t("customer_profile.edit")}
                  </LoadingButton>
                </>
              ) : (
                <>
                  <LoadingButton
                    variant="outlined"
                    color="primary"
                    onClick={() => setEditMode(false)}
                  >
                    {t("customer_profile.cancel")}
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleUpdateCustomerSubmit((data) =>
                      updateCustomerMutate({
                        data: {
                          ...(data as Customer),
                        },
                        id: customerId?.toString() ?? "",
                      }),
                    )}
                  >
                    {t("customer_profile.update")}
                  </LoadingButton>
                </>
              )}
            </Stack>
          </Stack>
          {customerDetails && (
            <Box>
              <Typography variant="h5" py={4}>
                {t("customer_profile.company_details")}
              </Typography>
              <FormTextField
                key={"companyName"}
                control={control}
                name={"companyName"}
                label={t("customer_profile.company_name")}
                disabled={editMode}
              />
              <Typography variant="h5" py={4}>
                {t("customer_profile.customer_details")}
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"firstName"}
                    control={control}
                    name={"firstName"}
                    label={t("customer_profile.customer_infos.first_name")}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"lastName"}
                    control={control}
                    name={"lastName"}
                    label={t("customer_profile.customer_infos.last_name")}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"email"}
                    control={control}
                    name={"email"}
                    disabled={editMode}
                    label={t("customer_profile.customer_infos.email")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"address"}
                    control={control}
                    name={"address"}
                    disabled={editMode}
                    label={t("customer_profile.customer_infos.address")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"addressNumber"}
                    control={control}
                    name={"addressNumber"}
                    disabled={editMode}
                    label={t("customer_profile.customer_infos.number")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"region"}
                    control={control}
                    name={"region"}
                    disabled={editMode}
                    label={t("customer_profile.customer_infos.region")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormTextField
                    key={"zipCode"}
                    control={control}
                    name={"zipCode"}
                    disabled={editMode}
                    label={t("customer_profile.customer_infos.zip")}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <BirthdayDatePicker
                    key={"birthday"}
                    control={control}
                    name={"birthday"}
                    label={"birthday"}
                    disabled={!editMode}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormPhoneCodeField
                    key={"telephone"}
                    control={control}
                    name={"telephone"}
                    label={t("customer_profile.customer_infos.mobile_number")}
                    disabled={editMode}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormPhoneCodeField
                    key={"mobilePhone"}
                    control={control}
                    name={"mobilePhone"}
                    disabled={editMode}
                    label={t("customer_profile.customer_infos.phone")}
                  />
                </Grid>
              </Grid>
              {/* <Typography variant="h5" py={4}>
                More Details
              </Typography> */}
            </Box>
          )}
        </Stack>
      </BoxWithShadow>
      <Box py={4}>
        <TabContext value={tabsValue}>
          <TabsHead />
          <FamilyPanel />
          <InsurancePanel />
          <TaxesPanel />
          <ThreePylonsPanel />
          <ExtraDocumentsPanel />
          <MeetingNotesPanel />
        </TabContext>
      </Box>
      <FamilyMemberDialog />
      <DeleteCustomerDialog />
    </Box>
  );
};
