import React from "react";
import { CustomersContextProvider } from "./context";
import CustomersView from "./view";

const Customers = () => {
  return (
    <CustomersContextProvider>
      <CustomersView />
    </CustomersContextProvider>
  );
};

export default Customers;
