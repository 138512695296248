import { getJWT } from "./customers/mutations/fetch";
import axios from "axios";
import { toast } from "react-toastify";

export const fetcher = async (
  url: string,
  options?: RequestInit
): Promise<any> => {
  const token = await getJWT();
  const response = await fetch(url, {
    ...options,
    headers: { Authorization: "Bearer " + token, ...options?.headers },
  });
  if (!response.ok) {
    throw new Error(`An error occurred: ${response.status}`);
  }
  const data = await response.json();
  return data;
};

export async function deleteFile(
  url: string,
  { arg }: { arg: { id: number; type?: string; customer?: number | string } }
) {
  let customerId;
  if (arg.type) {
    arg.type = "/" + arg.type;
  } else {
    arg.type = "";
  }
  if (arg.customer) {
    customerId = "/" + arg.customer;
  } else {
    customerId = "";
  }
  const finalUrl = url + arg.id + arg.type + customerId;
  const token = await getJWT();
  const data = await axios
    .delete(finalUrl, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((response) => {
      toast.success("File deleted successfully");
      return response;
    })
    .catch((error) => {
      if (error) {
        toast.error("Error deleted file");
      }
    });
  return data?.data;
}
