import { TextFieldProps } from "@mui/material";
import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import "react-phone-input-2/lib/material.css";
import { ADD_CUSTOMER_FORM } from "../../AddCustomer/context/Forms/types";

interface Props {
  name: keyof ADD_CUSTOMER_FORM;
  label: string;
  control: any;
  selectProps?: TextFieldProps;
  disabled?: boolean;
}

function FormPhoneCodeField({
  control,
  name,
  label,
  selectProps,
  disabled = true,
}: Props) {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <>
          <MuiTelInput
            label={label}
            value={value}
            onChange={onChange}
            variant="outlined"
            fullWidth
            onBlur={onBlur}
            inputRef={ref}
            error={!!error}
            helperText={error && error.message}
            disabled={!disabled}
          />
        </>
      )}
    />
  );
}

export default FormPhoneCodeField;
