import useSWR from "swr";
import { deleteFile, fetcher } from "../initialize-api";
import { uploadFile } from "../common";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";

export const useInsuranceFiles = (id: string) => {
  const { data, error, isLoading, mutate } = useSWR(
    `/app/crm-logistic/api/insurance/${id}`,
    // `/insurance/${id}`,
    fetcher,
    {
      onErrorRetry(err, key, config, revalidate, revalidateOpts) {
        if (err.status === 404) return;
      },
    }
  );
  return {
    data,
    error,
    isLoading,
    mutate,
  };
};

export const useUploadInsuranceFile = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    `/app/crm-logistic/api/insurance/`,
    // `/insurance/`,
    uploadFile
  );

  return {
    trigger,
    isMutating,
    error,
  };
};

export const useDeleteInsuranceFile = () => {
  const { trigger, isMutating } = useSWRMutation(
    "/app/crm-logistic/api/insurance/",
    // "/insurance/",
    deleteFile // Add 'args' property to the payload object
  );

  return {
    trigger,
    isMutating,
  };
};
