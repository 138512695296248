import { FC, PropsWithChildren, createContext, useContext } from "react";

type UsersContextType = ReturnType<typeof useUsersContextProvider>;

const UsersContext = createContext({} as UsersContextType);

interface UsersContextProviderProps {}

export const useUsersContextProvider = (props: UsersContextProviderProps) => {
  return {};
};

export const UsersContextProvider: FC<PropsWithChildren> = (props) => {
  const { children, ...options } = props;
  const context = useUsersContextProvider(options);
  return (
    <UsersContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsersContext = () => useContext(UsersContext);
