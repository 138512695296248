import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const CustomerTableHeader = () => {
  const { t } = useTranslation();
  return (
    <TableHead sx={{ bgcolor: "grey.100" }}>
      <TableRow>
        <TableCell>{t("dashboard.table.name")}</TableCell>
        <TableCell>{t("dashboard.table.email")}</TableCell>
        <TableCell>{t("dashboard.table.phone")}</TableCell>
        <TableCell>{t("dashboard.table.address")}</TableCell>
        <TableCell>{t("dashboard.table.actions")}</TableCell>
      </TableRow>
    </TableHead>
  );
};
