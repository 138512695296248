import {
  alpha,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { useCustomerProfileContext } from "../CustomerProfile/context/customer-profile-context";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useDashboardContext } from "../Dashboard/context/dashboard-context";

export const DeleteCustomerDialogDashboard = ({
  customerId,
}: {
  customerId: number;
}) => {
  const theme = useTheme();
  const {
    deleteCustomer: { handleDeleteCustomerById },
    deleteCustomerDialog: {
      deleteCustomerDialogOpen,
      handleDeleteCustomerDialogClose,
    },
  } = useDashboardContext();
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        open={deleteCustomerDialogOpen}
        onClose={handleDeleteCustomerDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px",
            padding: "20px",
            minWidth: "400px",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Avatar
            sx={{
              backgroundColor: alpha(theme?.palette?.warning?.main, 0.3),
              color: "error.contrastText",
              margin: "auto",
              padding: 3,
            }}
            sizes={"large"}
          >
            <WarningAmberIcon color="warning" fontSize="large" />
          </Avatar>
          <Typography variant="h5" fontWeight={"bold"} textAlign={"center"}>
            {t("dialog.delete_customer.title")}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ textAlign: "center" }}
          >
            {t("dialog.delete_customer.description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            onClick={handleDeleteCustomerDialogClose}
            variant={"text"}
            color={"primary"}
            sx={{ color: "black" }}
          >
            {t("dialog.delete_customer.cancel")}
          </LoadingButton>
          <LoadingButton
            onClick={() => handleDeleteCustomerById(customerId)}
            autoFocus
            variant="contained"
            color="error"
          >
            {t("dialog.delete_customer.confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
