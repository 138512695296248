import useSWR from "swr";
import { deleteFile, fetcher } from "../initialize-api";
import { uploadFile } from "../common";
import useSWRMutation from "swr/mutation";
import { toast } from "react-toastify";

export const useThreePylonsFiles = (id: string) => {
  const { data, error, isLoading } = useSWR(
    `/app/crm-logistic/api/three-pylons/${id}`,
    // `/three-pylons/${id}`,
    fetcher
  );

  return {
    data,
    error,
    isLoading,
  };
};

export const useUploadThreePylonsFile = () => {
  const { trigger, isMutating, error } = useSWRMutation(
    `/app/crm-logistic/api/three-pylons/`,
    // `/three-pylons/`,
    uploadFile
  );

  return {
    trigger,
    isMutating,
    error,
  };
};

export const useDeleteThreePylonsFile = () => {
  const { trigger, isMutating } = useSWRMutation(
    "/app/crm-logistic/api/three-pylons/",
    // "/three-pylons/",
    deleteFile // Add 'args' property to the payload object
  );

  return {
    trigger,
    isMutating,
  };
};
