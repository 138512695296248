import {
  Box,
  LinearProgress,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import { DateTime } from "luxon";
import { CustomerTableHeader } from "./customerTable/customer-table-header";
import { useDashboardContext } from "./context/dashboard-context";
import { CustomerTableRow } from "./customerTable/customer-table-row";
import { ROUTES } from "../../helpers/routes";
import { LoadingButton } from "@mui/lab";
import Iconify from "../common/Iconify";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export const DashboardContent = () => {
  const {
    customersList: {
      customersListData,
      customersListLoading,
      totalPages,
      handlePageChange,
      paginationTable,
      handleSearch,
      searchValue,
    },
    deleteCustomer: { handleDeleteCustomerById },
  } = useDashboardContext();

  const { t } = useTranslation();

  const [searchValueInput, setSearchValueInput] = useState("");

  const handleSearchInputChange = (e: string) => {
    setSearchValueInput(e);
  };

  const handleSearchButtonClick = () => {
    handleSearch(searchValueInput);
  };

  return (
    <Box>
      <Stack
        direction={"row"}
        spacing={2}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography fontWeight={"bold"} variant="h4">
          {t("dashboard.title")}
        </Typography>
        <Link
          to={`${ROUTES.dashboard.customers.root}/${ROUTES.dashboard.customers.add}`}
        >
          <LoadingButton
            variant="contained"
            sx={{
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            startIcon={<Iconify icon={"solar:add-circle-linear"} height={25} />}
          >
            {t("dashboard.add_customer_button")}
          </LoadingButton>
        </Link>
      </Stack>

      <Box py={8}>
        <Stack
          direction="row"
          spacing={2}
          alignItems="center"
          py={2}
          justifyContent={"right"}
        >
          <TextField
            label={t("dashboard.search")}
            variant="outlined"
            size="small"
            value={searchValueInput}
            onChange={(e) => handleSearchInputChange(e.target.value)}
          />
          <LoadingButton
            variant="contained"
            startIcon={<Iconify icon="solar:search" height={25} />}
            onClick={handleSearchButtonClick}
          >
            {t("dashboard.search")}
          </LoadingButton>
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <CustomerTableHeader />
            {customersListLoading && (
              <TableRow>
                <TableCell sx={{ padding: 0 }} colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            <TableBody>
              {customersListData?.map((customer, index) => (
                <CustomerTableRow key={index} customer={customer} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display={"flex"} justifyContent={"center"} py={4}>
          <Pagination
            count={totalPages}
            page={paginationTable?.page + 1}
            onChange={handlePageChange}
          />
        </Box>
      </Box>
    </Box>
  );
};
