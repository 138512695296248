import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useCustomerProfileContext } from "../../context/customer-profile-context";
import FormTextField from "../../../common/FormTextfield";
import FormPhoneCodeField from "../../../common/FormPhoneCodeField";
import FormSelect from "../../../common/FormSelect";
import { FAMILY_MODAL_MODE } from "../../../../api/customers/types";
import UpdateMember from "./dialog-actions/update-member";
import AddNewMember from "./dialog-actions/add-new-member";
import { useTranslation } from "react-i18next";

interface FamilyMemberDialogProps {}

export const FamilyMemberDialog = ({}: FamilyMemberDialogProps) => {
  const {
    customerId,
    family: {
      familyModalOpen,
      handleCloseFamilyModal,
      familyControl,
      familyRelationships,
      newFamilyMemberLoading,
      handleFamilySubmit,
      handleNewFamilyMember,
      familyModalMode,
    },
  } = useCustomerProfileContext();

  const { t } = useTranslation();

  return (
    <Dialog
      PaperProps={{
        sx: {
          padding: 4,
          width: 500,
        },
      }}
      onClose={handleCloseFamilyModal}
      open={familyModalOpen}
    >
      <DialogTitle>
        <Typography variant="h5" fontWeight={"bold"}>
          {familyModalMode === FAMILY_MODAL_MODE.ADD
            ? t("customer_profile.tabs.family.add_family_member")
            : t("customer_profile.tabs.family.edit_family_member")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Stack display={"flex"} direction={"column"} gap={3} py={4}>
          <FormTextField
            key={"firstName"}
            control={familyControl}
            name={"firstName"}
            label={t("customer_profile.tabs.family.first_name")}
          />
          <FormTextField
            key={"lastName"}
            control={familyControl}
            name={"lastName"}
            label={t("customer_profile.tabs.family.last_name")}
          />
          <FormPhoneCodeField
            key={"mobilePhone"}
            control={familyControl}
            name={"mobilePhone"}
            label={t("customer_profile.tabs.family.phone")}
          />
          <FormSelect
            key={"relationship"}
            control={familyControl}
            name={"relationship"}
            label={t("customer_profile.tabs.family.relationship.title")}
            options={familyRelationships}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box display={"flex"} justifyContent={"flex-end"} py={3}>
          {/* <LoadingButton
            variant={"contained"}
            sx={{
              backgroundColor: "black",
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            startIcon={
              <Iconify icon={"solar:sd-card-line-duotone"} height={25} />
            }
            loading={newFamilyMemberLoading}
            onClick={handleFamilySubmit((data) => handleNewFamilyMember(data))}
          >
            Save
          </LoadingButton> */}
          {familyModalMode === FAMILY_MODAL_MODE.ADD ? (
            <AddNewMember />
          ) : (
            <UpdateMember />
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};
