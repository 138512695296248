import { LoadingButton, TabPanel } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { getJWT } from "../../../../../api/customers/mutations/fetch";
import { FilesTableContainer } from "../../../../common/FilesTable/files-table-container";
import Iconify from "../../../../common/Iconify";
import { FileContainer } from "../../files/file-container";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";
import { handleDownload } from "../../../../../helpers/file-downloader";
import { useTranslation } from "react-i18next";

async function getFiles(url: string) {
  const token = await getJWT();
  const data = await axios.get(url, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  return data?.data;
}

export const ExtraDocumentsPanel = () => {
  const { id: customerId } = useParams();
  const { t } = useTranslation();
  const {
    extraDocuments: {
      uploadExtraDocumentsFile,
      extraDocumentsFileIsUploading,
      deleteExtraDocumentsFile,
    },
    files: { setFileInQueue, fileInQueue },
  } = useCustomerProfileContext();

  const { data: extraDocumentsFile, mutate: refetchExtraDocumentsFiles } =
    useSWR(
      // `/extra-documents/${customerId}`,
      `/app/crm-logistic/api/extra-documents/${customerId}`,
      getFiles,
    );

  const handleFileDrop = useCallback((acceptedFiles: File[]) => {
    setFileInQueue(acceptedFiles);
  }, []);

  const handleUpload = async () => {
    const formData = new FormData();
    if (!fileInQueue || !customerId) return;
    formData.append("customerId", customerId);
    formData.append("document", fileInQueue?.[0]);
    formData.append("fileName", fileInQueue?.[0]?.name);

    uploadExtraDocumentsFile(formData, {
      onSuccess: () => refetchExtraDocumentsFiles(),
    });
    setFileInQueue(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
    },
    multiple: false,
  });

  const COLUMNS: { name: string; align: "left" | "right" | "center" }[] = [
    {
      name: t("table.name"),
      align: "left",
    },

    {
      name: t("generic.actions"),
      align: "right",
    },
  ];

  return (
    <TabPanel value="extraDocuments">
      <Grid container spacing={2} component={"form"}>
        <Grid item xs={12}>
          {fileInQueue ? (
            <Stack direction={"column"} gap={3}>
              <FileContainer />
              <LoadingButton
                variant={"contained"}
                sx={{
                  backgroundColor: "black",
                  "&:hover": {
                    backgroundColor: "gray",
                  },
                }}
                startIcon={
                  <Iconify
                    icon={"solar:upload-minimalistic-line-duotone"}
                    height={25}
                  />
                }
                onClick={handleUpload}
                loading={extraDocumentsFileIsUploading}
              >
                {t("generic.upload")}
              </LoadingButton>
            </Stack>
          ) : (
            <Box
              {...getRootProps()}
              sx={{
                border: "2px dashed",
                borderColor: "grey.300",
                padding: "20px",
                textAlign: "center",
                borderRadius: "5px",
              }}
            >
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography>Drop the files here ...</Typography>
              ) : (
                <Typography>{t("generic.drag_and_drop")}</Typography>
              )}
            </Box>
          )}
        </Grid>
      </Grid>
      <Box py={4}>
        <Divider>
          <Typography>Files</Typography>
        </Divider>

        <FilesTableContainer columns={COLUMNS} rows={extraDocumentsFile ?? []}>
          {Array.isArray(extraDocumentsFile) &&
            extraDocumentsFile?.map((row: any) => (
              <TableRow
                key={row.fileName}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row" align="left">
                  {row.fileName}
                </TableCell>

                <TableCell>
                  <Stack
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    direction={"row"}
                    p={2}
                    width={1}
                  >
                    <IconButton
                      value="download"
                      onClick={() => handleDownload(row?.document ?? "")}
                    >
                      <Iconify icon={"solar:download-outline"} height={25} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        deleteExtraDocumentsFile(
                          {
                            id: row?.id,
                          },
                          { onSuccess: () => refetchExtraDocumentsFiles() },
                        )
                      }
                    >
                      <Iconify
                        icon={"solar:trash-bin-minimalistic-bold"}
                        height={25}
                      />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
        </FilesTableContainer>
      </Box>
    </TabPanel>
  );
};
