import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import CustomersTableList from "../Table";
import { ROUTES } from "../../../helpers/routes";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";

const CustomersView = () => {
  return (
    <Box>
      <Box>
        <Stack
          direction={"row"}
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontWeight={"bold"} variant="h4">
            Customers
          </Typography>
          <Link to={ROUTES.dashboard.customers.add}>
            <LoadingButton
              variant="contained"
              sx={{
                "&:hover": {
                  backgroundColor: "gray",
                },
              }}
              startIcon={<Icon icon={"solar:add-circle-linear"} height={25} />}
            >
              Add customer
            </LoadingButton>
          </Link>
        </Stack>
        <Box py={4}>
          <CustomersTableList />
        </Box>
      </Box>
    </Box>
  );
};

export default CustomersView;
