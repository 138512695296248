import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { useMutation, useQuery } from "react-query";
import { getCustomers } from "../../../api/customers/queries/query";
import { deleteCustomerById } from "../../../api/customers/mutations/query";
import { toast } from "react-toastify";
// import { useDebounce } from "use-debounce";

type DashboardContextType = ReturnType<typeof useDashboardContextProvider>;

const DashboardContext = createContext({} as DashboardContextType);

interface DashboardContextProviderProps {}

export const useDashboardContextProvider = (
  props: DashboardContextProviderProps,
) => {
  const [deleteCustomerDialogOpen, setDeleteCustomerDialogOpen] =
    useState(false);

  const handleDeleteCustomerDialogOpen = () => {
    setDeleteCustomerDialogOpen(true);
  };

  const handleDeleteCustomerDialogClose = () => {
    setDeleteCustomerDialogOpen(false);
  };
  const [paginationTable, setPaginationTable] = useState({
    pageSize: 10,
    page: 0,
  });

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (value: string) => {
    setSearchValue(value);
    refetchCustomersList();
  };
  const {
    data: customersList,
    isLoading: customersListLoading,
    error: customersListError,
    refetch: refetchCustomersList,
  } = useQuery(
    ["customers", paginationTable.page, paginationTable.pageSize, searchValue],
    () =>
      getCustomers(paginationTable.page, paginationTable.pageSize, searchValue),
  );
  const customersListData = useMemo(() => {
    return (
      customersList?.content?.map((customer, index) => {
        return {
          id: index,
          ...customer,
        };
      }) ?? []
    );
  }, [customersList]);

  const totalPages = useMemo(() => {
    return customersList?.totalPages;
  }, [customersList]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPaginationTable({
      ...paginationTable,
      page: value - 1,
    });
  };

  const { data, mutate: deleteCustomerMutate } =
    useMutation(deleteCustomerById);

  const handleDeleteCustomerById = (id: number) => {
    deleteCustomerMutate(id, {
      onSuccess: (data) => {
        refetchCustomersList();
        handleDeleteCustomerDialogClose();
        toast.success("Customer deleted successfully");
      },
      onError: () => {
        toast.error("Ohh! Something went wrong. Please try again");
      },
    });
  };

  return {
    customersList: {
      customersListData,
      customersListLoading,
      customersListError,
      totalPages,
      handlePageChange,
      paginationTable,
      handleSearch,
      searchValue,
    },
    deleteCustomer: {
      handleDeleteCustomerById,
    },
    deleteCustomerDialog: {
      deleteCustomerDialogOpen,
      handleDeleteCustomerDialogOpen,
      handleDeleteCustomerDialogClose,
    },
  };
};

export const DashboardContextProvider: FC<PropsWithChildren> = (props) => {
  const { children, ...options } = props;
  const context = useDashboardContextProvider(options);
  return (
    <DashboardContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => useContext(DashboardContext);
