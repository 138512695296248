import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { fileDownloader } from "../../../helpers/file-downloader";

interface Props {
  columns: {
    name: string;
    align: "right" | "left" | "center";
  }[];
  rows: any[];
  remove?: (id: number) => void;
  children?: React.ReactNode;
}

export const FilesTableContainer = ({
  children,
  columns,
  rows,
  remove,
}: Props) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell key={index} align={column?.align}>
                {column?.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
};
