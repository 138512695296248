import { z } from "zod";

export const ADD_CUSTOMER_VALIDTION = z.object({
  firstName: z.string().min(1, { message: "Firstname is required" }),
  lastName: z.string().min(1, { message: "Lastname is required" }),
  email: z.string().email().min(1, { message: "Email is required" }),
  telephone: z.string().optional(),
  mobilePhone: z.string().optional(),
  address: z.string().min(1, { message: "Address is required" }),
  addressNumber: z.string().min(1, { message: "Address number is required" }),
  region: z.string().min(1, { message: "Region is required" }),
  zipCode: z.string().min(1, { message: "Zip code is required" }),
  birthday: z.coerce.date(),
  companyName: z.string().optional(),
});
