import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import AppRouter from "./components/routers/AppRouter";
import theme from "./theme/theme";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session from "supertokens-auth-react/recipe/session";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTime } from "luxon";

function App() {
  SuperTokens.init({
    appInfo: {
      // learn more about this on https://supertokens.com/docs/emailpassword/appinfo
      appName: "Link Enterprise CRM",
      apiDomain: process.env.REACT_APP_API_ENDPOINT as string,
      websiteDomain: process.env.REACT_APP_API_ENDPOINT as string,
      apiBasePath: "/auth",
      websiteBasePath: "/",
    },
    recipeList: [
      EmailPassword.init({
        signInAndUpFeature: {
          disableDefaultUI: true,
        },
        getRedirectionURL: async (context) => {
          if (context.action === "SUCCESS") {
            if (context.redirectToPath !== undefined) {
              // we are navigating back to where the user was before they authenticated
              return context.redirectToPath;
            }
            if (context.isNewPrimaryUser) {
              // user signed up
            } else {
              // user signed in
            }
            return "/dashboard";
          }

          return undefined;
        },
      }),
      Session.init({
        tokenTransferMethod: "header", // or "cookie"
      }),
    ],
  });

  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: 2, refetchOnWindowFocus: false } },
  });

  return (
    <Box className="App">
      <SuperTokensWrapper>
        <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="de">
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <AppRouter />
            </ThemeProvider>
          </QueryClientProvider>
        </LocalizationProvider>
        <ToastContainer />
      </SuperTokensWrapper>
    </Box>
  );
}

export default App;
