import { MainLayoutContextProvider } from "../../../context/MainLayoutContext";
import MainLayoutView from "./MainLayoutView";

function MainLayout() {
  return (
    <MainLayoutContextProvider>
      <MainLayoutView />
    </MainLayoutContextProvider>
  );
}

export default MainLayout;
