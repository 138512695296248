import { createTheme, outlinedInputClasses } from "@mui/material";
import { red } from "@mui/material/colors";

// FIXME  : Θελω να μου κανεις το shadow array optimized

const theme = createTheme({
  palette: {
    common: {
      black: "#252a2e",
      white: "#ffffff",
    },
    primary: {
      main: "#1976d4",
    },
    secondary: {
      main: "#6e757a",
    },
    text: {
      primary: "#252a2e",
      secondary: "#bdc3c1",
    },
  },
  shadows: [
    "none",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 16px 32px 0px rgba(145, 158, 171, 0.12)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 24px 48px 0px rgba(145, 158, 171, 0.12)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 32px 64px 0px rgba(145, 158, 171, 0.12)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 48px 80px 0px rgba(145, 158, 171, 0.12)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 64px 128px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 80px 160px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 96px 192px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 120px 240px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 144px 288px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 180px 360px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 220px 440px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 260px 520px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 300px 600px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 340px 680px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 380px 760px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 420px 840px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 460px 920px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 500px 1000px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 540px 1080px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 580px 1160px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 620px 1240px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 660px 1320px 0px rgba(145, 158, 171, 0.24)",
    "0px 0px 2px 0px rgba(145, 158, 171, 0.2), 0px 700px 1400px 0px rgba(145, 158, 171, 0.24)",
  ],
  components: {
     MuiLinearProgress: {

      styleOverrides: {
        barColorPrimary: {
          backgroundColor: "#ca2127",
        },
        colorPrimary: {
          backgroundColor: "#f7cbcc",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: "xl",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#E0E3E7",
          "--TextField-brandBorderHoverColor": "#B2BAC2",
          "--TextField-brandBorderFocusedColor": "#6F7E8C",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
          borderRadius: 10,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "var(--TextField-brandBorderColor)",
          borderRadius: 10,
        },
        root: {
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderHoverColor)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:before, &:after": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: "black",
          color: "white",
        },
        containedError: {
          backgroundColor: red[50],
          color: "red",
          "&:hover": {
            backgroundColor: red[500],
            color: "white",
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            borderRadius: 200,
          },
        },
      },
    },

    MuiInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
            borderRadius: 10,
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
            borderRadius: 10,
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
            borderRadius: 10,
          },
          borderRadius: 10,
        },
      },
    },
  },
});

export default theme;
