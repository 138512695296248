import {
  FC,
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useMemo,
} from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCustomerById,
  getCustomerFamilysById,
} from "../../../api/customers/queries/query";
import {
  createFamilyMember,
  deleteCustomerById,
  deleteFamilyMember,
  getInsuranceByCustomerId,
  updateCustomerById,
  updateFamilyMember,
} from "../../../api/customers/mutations/query";
import {
  FAMILY_MODAL_MODE,
  FamilyMember,
  FamilyRelationship,
} from "../../../api/customers/types";
import { toast } from "react-toastify";
import { ROUTES } from "../../../helpers/routes";
import {
  useDeleteInsuranceFile,
  useInsuranceFiles,
  useUploadInsuranceFile,
} from "../../../api/insurance/insurance";
import {
  useDeleteTaxesFile,
  useUploadTaxesFile,
} from "../../../api/taxes/taxes";
import {
  useDeleteThreePylonsFile,
  useUploadThreePylonsFile,
} from "../../../api/three-pylons/three-pylons";
import {
  useDeleteExtraDocumentsFile,
  useUploadExtraDocumentsFile,
} from "../../../api/extra-documents/extra-documents";
import { useTranslation } from "react-i18next";

type CustomerProfileContextType = ReturnType<
  typeof useCustomerProfileContextProvider
>;

const CustomerProfileContext = createContext({} as CustomerProfileContextType);

interface CustomerProfileContextProviderProps {}

export const useCustomerProfileContextProvider = (
  props: CustomerProfileContextProviderProps,
) => {
  const { id: customerId } = useParams();
  const [deleteCustomerDialogOpen, setDeleteCustomerDialogOpen] =
    useState(false);

  const handleDeleteCustomerDialogOpen = () => {
    setDeleteCustomerDialogOpen(true);
  };

  const handleDeleteCustomerDialogClose = () => {
    setDeleteCustomerDialogOpen(false);
  };

  const [editMode, setEditMode] = useState<boolean>(false);
  const [familyMemberId, setFamilyMemberId] = useState<number | null>(null);

  const {
    control,
    reset,
    handleSubmit: handleUpdateCustomerSubmit,
  } = useForm({});

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [familyModalMode, setFamilyModalMode] =
    useState<FAMILY_MODAL_MODE | null>(null);

  const {
    control: familyControl,
    reset: familyReset,
    handleSubmit: handleFamilySubmit,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      mobilePhone: "",
      customerId: customerId,
      relationship: "",
    },
  });

  const {
    data: newFamilyMemberData,
    isLoading: newFamilyMemberLoading,
    mutate: mutateFamilyMember,
  } = useMutation(createFamilyMember);

  const {
    data: updateFamilyMemberData,
    isLoading: updateFamilyMemberLoading,
    mutate: mutateUpdateFamilyMember,
  } = useMutation(updateFamilyMember);

  const {
    data: deleteFamilyMemberData,
    isLoading: deleteFamilyMemberLoading,
    mutate: mutateDeleteFamilyMember,
  } = useMutation(deleteFamilyMember);

  const handleNewFamilyMember = (data: FamilyMember) => {
    mutateFamilyMember(data, {
      onSuccess: (data) => {
        handleCloseFamilyModal();
        familyReset();
        refetchCustomerFamily();

        toast.success("New member added successfully");
      },
    });
  };

  const handleUpdateFamilyMember = (data: FamilyMember) => {
    mutateUpdateFamilyMember(data, {
      onSuccess: (data) => {
        handleCloseFamilyModal();
        familyReset();
        refetchCustomerFamily();
        toast.success("Member updated successfully");
      },
    });
  };

  const handleDeleteFamilyMember = (id: number) => {
    mutateDeleteFamilyMember(id, {
      onSuccess: (data) => {
        refetchCustomerFamily();
        toast.success("Member deleted successfully");
      },
    });
  };

  const [tabsValue, setTabsValue] = useState<string>("family");
  const [familyModalOpen, setFamilyModalOpen] = useState<boolean>(false);
  const handleOpenFamilyModal = () => {
    setFamilyModalOpen(true);
  };

  const handleCloseFamilyModal = () => {
    setFamilyModalOpen(false);
  };

  const {
    data: customerDetails,
    isLoading: customerDetailsLoading,
    refetch: refetchCustomerDetails,
  } = useQuery(
    ["getCustomerById", customerId],
    () => getCustomerById(customerId ?? ""),
    {
      onSuccess: (data) => {
        if (data) {
          reset({
            ...data,
          });
        }
      },
    },
  );

  const {
    data: customerFamily,
    isLoading: customerFamilyLoading,
    refetch: refetchCustomerFamily,
  } = useQuery(["getCustomerFamilyById", customerId], () =>
    getCustomerFamilysById(customerId ?? ""),
  );

  const { data, mutate: deleteCustomerMutate } =
    useMutation(deleteCustomerById);

  const { data: updatedCistomer, mutate: updateCustomerMutate } = useMutation(
    updateCustomerById,
    {
      onSuccess: () => {
        refetchCustomerDetails();
        setEditMode(false);
      },
    },
  );

  const handleDeleteCustomerById = () => {
    if (customerId)
      deleteCustomerMutate(customerId, {
        onSuccess: () => {
          handleDeleteCustomerDialogClose();
          navigate(ROUTES?.dashboard.root);
        },
      });
  };

  const familyRelationships = useMemo(() => {
    return Object.keys(FamilyRelationship).map((key) => {
      return {
        value: key,
        label: t(`customer_profile.tabs.family.relationship.${key}`),
      };
    });
  }, []);

  const { trigger: uploadInsuranceFile, isMutating: insuranceFileIsUploading } =
    useUploadInsuranceFile();

  const { trigger: deleteInsuranceFile, isMutating: insuranceFileIsDeleting } =
    useDeleteInsuranceFile();

  const { trigger: uploadTaxesFile, isMutating: taxesFileIsUploading } =
    useUploadTaxesFile();
  const { trigger: deleteTaxesFile, isMutating: taxesFileIsDeleting } =
    useDeleteTaxesFile();

  const {
    trigger: uploadThreePylonsFile,
    isMutating: threePylonsFileIsUploading,
  } = useUploadThreePylonsFile();
  const {
    trigger: deleteThreePylonsFile,
    isMutating: threePylonsFileIsDeleting,
  } = useDeleteThreePylonsFile();

  const {
    trigger: uploadExtraDocumentsFile,
    isMutating: extraDocumentsFileIsUploading,
  } = useUploadExtraDocumentsFile();
  const {
    trigger: deleteExtraDocumentsFile,
    isMutating: extraDocumentsFileIsDeleting,
  } = useDeleteExtraDocumentsFile();

  // File uploads

  const [fileInQueue, setFileInQueue] = useState<File[] | null>(null);

  const handleTabsValue = (event: React.SyntheticEvent, value: string) => {
    setTabsValue(value);
    setFileInQueue(null);
  };
  return {
    customerId,
    form: {
      control,
    },
    edit: {
      editMode,
      setEditMode,
    },
    customersDetails: {
      customerDetails,
      customerDetailsLoading,
      handleDeleteCustomerById,
      handleUpdateCustomerSubmit,
      updateCustomerMutate,
    },
    tabs: {
      tabsValue,
      handleTabsValue,
    },
    family: {
      customerFamily,
      familyModalOpen,
      handleOpenFamilyModal,
      handleCloseFamilyModal,
      handleFamilySubmit,
      handleNewFamilyMember,
      familyControl,
      familyRelationships,
      newFamilyMemberLoading,
      familyModalMode,
      setFamilyModalMode,
      familyReset,
      handleUpdateFamilyMember,
      setFamilyMemberId,
      familyMemberId,
      handleDeleteFamilyMember,
    },
    files: {
      fileInQueue,
      setFileInQueue,
    },
    insuranceFile: {
      uploadInsuranceFile,
      insuranceFileIsUploading,
      deleteInsuranceFile,
    },
    taxes: {
      uploadTaxesFile,
      taxesFileIsUploading,
      deleteTaxesFile,
    },
    threePylons: {
      uploadThreePylonsFile,
      threePylonsFileIsUploading,
      deleteThreePylonsFile,
    },
    extraDocuments: {
      uploadExtraDocumentsFile,
      extraDocumentsFileIsUploading,
      deleteExtraDocumentsFile,
    },
    deleteCustomerDialog: {
      deleteCustomerDialogOpen,
      handleDeleteCustomerDialogOpen,
      handleDeleteCustomerDialogClose,
    },
  };
};

export const CustomerProfileContextProvider: FC<PropsWithChildren> = (
  props,
) => {
  const { children, ...options } = props;
  const context = useCustomerProfileContextProvider(options);
  return (
    <CustomerProfileContext.Provider
      value={{
        ...context,
      }}
    >
      {children}
    </CustomerProfileContext.Provider>
  );
};

export const useCustomerProfileContext = () =>
  useContext(CustomerProfileContext);
