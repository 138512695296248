import { Customer, FamilyMember, PaginatedCustomers } from "../types";
import {
  fetchAddNewInsurance,
  fetchAllInsuranceForCustomer,
  fetchCreateFamilyMember,
  fetchDeleteCustomerById,
  fetchDeleteFamilyMember,
  fetchNewCustomer,
  fetchUpdateCustomerById,
  fetchUpdateFamilyMember,
} from "./fetch";

export const addNewCustomer = async (data: Customer): Promise<Customer> => {
  const response = await fetchNewCustomer(data);
  return response.data;
};

export const deleteCustomerById = async (id: number | string) => {
  const response = await fetchDeleteCustomerById(id);
  return response.data;
};

export const createFamilyMember = async (data: FamilyMember) => {
  const response = await fetchCreateFamilyMember(data);
  return response.data;
};

export const updateFamilyMember = async (data: FamilyMember) => {
  const response = await fetchUpdateFamilyMember(data);
  return response.data;
};

export const deleteFamilyMember = async (id: number) => {
  const response = await fetchDeleteFamilyMember(id);
  return response.data;
};

export const uploadNewInsurance = async (data: any) => {
  const response = await fetchAddNewInsurance(data);
  return response.data;
};

export const getInsuranceByCustomerId = async (id: string) => {
  const response = await fetchAllInsuranceForCustomer(id);
  return response.data;
};

export const updateCustomerById = async (data: {
  data: Customer;
  id: string;
}) => {
  const response = await fetchUpdateCustomerById(data);
  return response.data;
};
