import { useMemo, useState } from "react";
import {
  TabPanel,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";
import { timelineOppositeContentClasses } from "@mui/lab/TimelineOppositeContent";
import { Box, Button, Stack, TextField } from "@mui/material";
import {
  useGetMeetingNotes,
  usePostMeetingNotes,
  useUpdateMeetingNotes,
} from "../../../../../api/meeting-notes/meeting-notes";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

export const MeetingNotesPanel = () => {
  const { customerId } = useCustomerProfileContext();
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [editedMeetingNote, setEditedMeetingNote] = useState<null | number>(
    null,
  );
  const [meetingNoteUpdate, setMeetingNoteUpdate] = useState<null | string>(
    null,
  );
  const [ITEMS, setITEMS] = useState<
    {
      customerId: string | undefined;
      date: string;
      comments: string;
    }[]
  >([]);

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  };

  const handleUpdateMeetingNote = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setMeetingNoteUpdate(event.target.value);
  };

  const {
    data: meetingsNotes,
    isLoading: loadingMeetingsNotes,
    refetch,
  } = useGetMeetingNotes(customerId ?? "");

  const handleAddComment = () => {
    if (comment !== "") {
      const newComment = {
        customerId: customerId ?? "", // Change the type of customerId to number
        date: new Date().toISOString(),
        comments: comment,
      };
      setITEMS([newComment, ...ITEMS]);
      setComment(""); // Clear the comment after adding
      postComment(newComment, {
        onSuccess: () => {
          refetch();
        },
      });
    }
  };

  const handleUpdateComment = (index: number) => {
    const updatedComment = {
      customerId: customerId ?? "", // Change the type of customerId to number
      date: new Date().toISOString(),
      comments: meetingNoteUpdate ?? "",
      commentId: index.toString(),
    };
    updateMeetingNote(updatedComment, {
      onSuccess: () => {
        refetch();
        setEditedMeetingNote(null);
      },
    });
  };

  const { trigger: postComment } = usePostMeetingNotes();

  const { trigger: updateMeetingNote } = useUpdateMeetingNotes();

  const commentsData = useMemo(() => {
    return (
      Array.isArray(meetingsNotes) &&
      meetingsNotes?.map((comment) => {
        const { customer, ...rest } = comment;
        return rest;
      })
    );
  }, [meetingsNotes]);

  return (
    <TabPanel value="comments">
      <TextField
        label={t("notes.meeting_notes")}
        value={comment}
        onChange={handleCommentChange}
        multiline
        rows={4}
        fullWidth
      />

      <Stack alignItems={"right"} justifyContent={"right"}>
        <Button
          variant="contained"
          color="primary"
          disabled={comment === ""}
          onClick={handleAddComment}
          sx={{ marginTop: 2 }}
        >
          {t("notes.add_note")}
        </Button>
      </Stack>

      <Timeline
        sx={{
          [`& .${timelineOppositeContentClasses.root}`]: {
            flex: 0.2,
          },
        }}
      >
        {Array.isArray(commentsData) &&
          commentsData?.map((item, index) => (
            <TimelineItem key={index}>
              <TimelineOppositeContent color="textSecondary">
                {DateTime.fromISO(item.date).toFormat("dd/MM/yyyy")}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Box pb={2} textAlign={"right"}>
                  <TextField
                    value={
                      meetingNoteUpdate && editedMeetingNote === index
                        ? meetingNoteUpdate
                        : item.comments
                    }
                    onChange={handleUpdateMeetingNote}
                    multiline
                    disabled={editedMeetingNote !== index}
                    rows={2}
                    fullWidth
                  />
                  {editedMeetingNote === index && (
                    <Stack
                      direction={"row"}
                      gap={1}
                      py={2}
                      justifyContent={"flex-end"}
                    >
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => setEditedMeetingNote(null)}
                      >
                        {t("generic.cancel")}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => handleUpdateComment(item?.id)}
                      >
                        {t("generic.save")}
                      </Button>
                    </Stack>
                  )}
                  {editedMeetingNote !== index && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setEditedMeetingNote(index);
                      }}
                      sx={{ marginTop: 2 }}
                    >
                      {t("generic.update")}
                    </Button>
                  )}
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
      </Timeline>
    </TabPanel>
  );
};
