import React from "react";
import { useCustomerProfileContext } from "../../../context/customer-profile-context";
import { TabPanel } from "@mui/lab";
import { NewFamilyMemberCard } from "../../family/new-family-member-card";
import { Grid } from "@mui/material";
import { FamilyMemberCard } from "../../family/family-member-card";

export const FamilyPanel = () => {
  const {
    family: { customerFamily },
  } = useCustomerProfileContext();

  return (
    <TabPanel value="family">
      <Grid container spacing={2} alignItems={"stretch"}>
        <Grid item xs={6} lg={3}>
          <NewFamilyMemberCard />
        </Grid>
        {customerFamily?.map((familyMember, index) => (
          <Grid item xs={6} lg={3} key={familyMember.id}>
            <FamilyMemberCard
              id={familyMember.id ?? 0}
              firstName={familyMember.firstName}
              lastName={familyMember.lastName}
              phone={familyMember.mobilePhone}
              relation={familyMember.relationship}
            />
          </Grid>
        ))}
      </Grid>
    </TabPanel>
  );
};
