import { toast } from "react-toastify";
import { getJWT } from "../api/customers/mutations/fetch";

export const fileDownloader = async (url: string, fileName: string) => {
  try {
    const token = await getJWT();

    const response = await fetch(url, {
      headers: {
        Authorization: "Bearer " + token,
        "Allow-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Origin, X-Requested-With, Content-Type, Accept",
      },
      credentials: "include",
    }).catch((error) => {
      return error;
    });

    if (!response.ok) {
      toast.error("Failed to download file");
      return;
    }

    console.log(fileName);

    const blob = await response.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

export const handleDownload = async (url: string) => {
  const parts = url.split("/");
  const index = parts.indexOf("documents");
  const nameFile = parts[parts.length - 1];
  const documentType = parts[parts.length - 2];
  const customerId = parts[parts.length - 3];
  const newUrl = parts.slice(index).join("/");
  await fileDownloader(
    "/app/crm-logistic/api/documents/" +
      customerId +
      "/" +
      documentType +
      "/" +
      newUrl,
    nameFile,
  );
};
