import { Box, BoxProps } from "@mui/material";
import React from "react";

interface BoxWithShadowProps extends BoxProps {
  children: React.ReactNode;
}

const BoxWithShadow: React.FC<BoxWithShadowProps> = ({
  children,
  padding = 4,
  ...options
}) => {
  return (
    <Box
      sx={{
        boxShadow: 1,
        padding: padding,
        borderRadius: 4,
      }}
      {...options}
    >
      {children}
    </Box>
  );
};

export default BoxWithShadow;
