import React from "react";
import { ADD_CUSTOMER_FORM } from "../../AddCustomer/context/Forms/types";
import { Controller } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";

interface Props {
  name: keyof ADD_CUSTOMER_FORM;
  label: string;
  control: any;
  disabled?: boolean;
}

export const BirthdayDatePicker = ({
  control,
  name,
  label,
  disabled = true,
}: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <>
          <DatePicker
            value={DateTime.fromISO(value)}
            disableFuture
            disabled={disabled}
            format="dd/MM/yyyy"
            onChange={onChange}
            sx={{ width: "100%" }}
            slotProps={{
              textField: {
                onBlur: onBlur,
                inputRef: ref,
                error: !!error,
                helperText: error ? error.message : "",
              },
            }}
          />
        </>
      )}
    />
  );
};
