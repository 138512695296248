import React from "react";
import BoxWithShadow from "../BoxWithShadow";
import { Box, InputAdornment, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Iconify from "../Iconify";
import { useCustomersContext } from "../../Customers/context";

interface TableProps {
  rows?: any;
  columns?: any;
  loading?: boolean;
}

const TableWithSearch = ({ rows, columns, loading }: TableProps) => {
  const {
    customersList: { totalPages, customersListData },
    search: { searchValue, handleSearchField },
    pagination: { paginationTable, setPaginationTable },
  } = useCustomersContext();
  return (
    <BoxWithShadow padding={0}>
      <Box display={"flex"} justifyContent={"right"} p={3}>
        <TextField
          variant="outlined"
          placeholder="Search..."
          value={searchValue}
          onChange={(event) => handleSearchField(event.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={"solar:magnifer-broken"} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box sx={{ height: 1 }}>
        <DataGrid
          loading={loading}
          rows={customersListData ?? []}
          rowHeight={80}
          columns={columns ?? []}
          paginationModel={paginationTable}
          onPaginationModelChange={setPaginationTable}
          paginationMode="server"
          sx={{
            border: 0,
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: "rgb(244, 246, 248)",
              width: "100%",
            },
            "& .MuiDataGrid-root": {
              backgroundColor: "rgb(244, 246, 248)",
              borderStyle: "dashed",
              width: "100%",
            },

            "& .MuiDataGrid-cell": {
              borderBottom: "1px dashed #ddd",
            },

            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          }}
          rowCount={totalPages ?? 0}
          pageSizeOptions={[5]}
          disableRowSelectionOnClick
        />
      </Box>
    </BoxWithShadow>
  );
};

export default TableWithSearch;
